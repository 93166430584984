<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="900"
    >
      <Message
          :message="message"
          @closeAlert="message.show = false"
      ></Message>
      <v-card>
        <v-card-title class="text-h5" id="title">
          <v-icon class="pe-3" color="secondary" size="40">
            mdi-lock
          </v-icon>
          Passwort ändern
        </v-card-title>
        <v-card-text>
          <v-form ref="formular">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="ps-md-6">
                  <v-text-field
                      v-model="oldPassword"
                      label="Altes Passwort"
                      :readonly="false"
                      type="password"
                  >
                  </v-text-field>

                </v-col>
                <v-col cols="12" md="6" class="ps-md-6">
                  <v-text-field
                      v-model="newPassword"
                      label="Neues Passwort"
                      :readonly="false"
                      type="password"
                  >
                  </v-text-field>
                  <v-text-field
                      v-model="newPasswordRepeat"
                      label="Passwort wiederholen"
                      :readonly="false"
                      type="password"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="createTimePlanEntry"
          >
            Ändern
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="resetForm"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "CreateTimePlanEntry",
  components: {
    Message,
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      message: msgObj(),
      menu: false,
      menu2: false,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    }
  },
  props: {
    dialog: Boolean,
    timePlanId: String
  },
  mounted() {},
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    resetForm() {
      this.oldPassword = ""
      this.newPassword = ""
      this.newPasswordRepeat = ""
      this.$emit('hideDialog')
    },
    createTimePlanEntry() {
      if (this.newPasswordRepeat === this.newPassword) {
        this.$store.dispatch('auth/changePassword', {
          uid: this.user.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        }).then(() => {
          this.hideDialog()
          this.message = success('Passwort wurde erfolgreich geändert.')
        }).catch((err) => {
          this.message = error(err)
        })
      }else{
        this.message = error('Passwörter müssen übereinstimmen.')
      }
    },
  },
}
</script>
